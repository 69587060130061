export const UrlCollectionSarang = {
    DashBoardHome: '/',
    HerdsmanList: '/danh-sach-swat',
    HerdsmanWMList: '/danh-sach-swat-wm',
    HerdsmanListPointThreeMonth: '/diem-tich-luy-swat',
    CustomerList: '/danh-sach-chien',
    CustomerNeedHelpList: '/ta-lang-can-giup-do',
    CalendarContact: '/lich-tiep-xuc',
    CalendarNeedHelp: '/lich-tiep-xuc-can-giup',
    // CalendarContactList: '/lich-tiep-xuc-danh-sach',
    SwatReportList: '/bao-cao-hoat-dong-swat',
    CuisineOrder: '/hoat-dong-am-thuc',
    ReportActivityShipping: '/bao-cao-hoat-dong-don-hang',
    SendCuisineToShipper: '/giao-shipper',
    DashboardCooking: '/bieu-do-am-thuc',
    DashboardShipping: '/bieu-do-don-hang',
    Department: '/ban-nganh',
    DepartmentListConfirmRank: '/ban-nganh-rank',
    Organization: '/to-chuc',
    Position: '/chuc-vu',
    Group: '/nhom',
    ZionArea: '/khu-vuc',
    Feedback: '/feedback',

    Profile: '/tai-khoan',
    RptCustomerStepDepartment: '/bao-cao-theo-buoc',
    ExamHerdsmanReport: '/xep-hang-swat',
    ExamDepartmentReport: '/xep-hang-doi-swat',
    ExamDeliveryReport: '/xep-hang-giao-van',
    ExamSumaryDepartmentReport: '/tong-hop-hoat-dong-biet-doi', 
    HerdsmanTalangSumaryReport: '/tong-hop-the-ta-lang', 
    ExamDashboard: '/xep-hang',
    Helping: '/huong-dan',
    MyReportAcademy: '/phat-bieu',
    ExamAcademyDashboard: '/vinh-danh-phat-bieu',
    ReportSumaryAcademy: '/thong-ke-phat-bieu',
    SwatTrundoInput: '/bao-cao-hoat-dong-trundo',
    SwatTrundoSumaryHerdsmanReport: '/tong-hop-hoat-dong-trundo-swat',
    SwatTrundoSumaryHerdsmanWMTeamReport: '/tong-hop-hoat-dong-trundo-swat-wm',
    SwatTrundoSumaryDepartmentReport: '/tong-hop-hoat-dong-trundo-biet-doi',
    SwatTrundoSumaryZionReport: '/tong-hop-hoat-dong-trundo-dia-vuc',
    SwatTrundoSumaryWMTeamReport: '/tong-hop-hoat-dong-trundo-wm',
    SwatTrundoSumaryByDateReport: '/chi-tiet-hoat-dong-trundo-swat',
    MemberCountInput: '/dang-ky-sl-phat-bieu',
    HerdsmanAcademyConfirm: '/bao-cao-phat-bieu'
};
