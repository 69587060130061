import React, { Component } from "react";
import { Route, Router, Switch } from "react-router-dom";
import RouteComponent from "./route-config.jsx";
import { UrlCollection } from "./common/url-collection";
import history from "./common/history";

//--- Loading
import AppLoading from "./components/loading/loading.view";
import AppSyncLoading from "./components/loading/loading-sync.view";

//--- Layout
import LayoutSarangView from "./components/layout-sarang/layout-sarang.view";
import LayoutView from "./components/layout/layout.view";
import LayoutViewWithHook from "./components/layout/layout.view.withHook";
import LayoutUserView from "./components/layout/layout-user.view.jsx";
import LayoutDetail from "./components/layout/layout-detail.view";

//--- Home
import Dashboard from "./modules/dashboard/dashboard.view.jsx";
//--- Admin
import Login from "./modules/login/login.view.jsx";
import ForgotPassword from "./modules/forgot-password/forgot-password.view.jsx";
import ResetPassword from "./modules/reset-password/reset-password.view.jsx";

//--- Consult the community
import EmailTemplate from "./modules/email-template/email-template";
import RoleManagement from "./modules/role-management/role-management";
import ContactManagement from "./modules/contact-management/contact-management.view";
import EmailGenerated from "./modules/email-generated/email-generated.view";
import UserManagement from "./modules/user-management/user-management.view";
import CommuneManagement from "./modules/communce-management/communce-management.view";
import ProviceManagement from "./modules/province-management/provice-management.view";
import DistrictManagement from "./modules/district-management/district-management.view";
import UserLogHistory from "./modules/user-log/user-log-history.view";
import LandTypeDetailManagement from "./modules/land-type-detail-management/land-type-detail-management.view";
//---Log
import Log from "./modules/log/log.jsx";

import ApprovalAgency from "./modules/approval-agency/approval-agency.view";

//--- Table Layer Structure
import TableStructure from "./modules/table-structure/table-structure.view.jsx";

//--- Access denied
import AccessDenied from "./modules/access-denied/access-denied.view.jsx";

//import DocumentManagement from "./modules/document-management/document-management.view.jsx";
import MyAccount from "./modules/my-account/my-account.view.jsx";
import PlanningUnit from "./modules/planning-unit/planning-unit.view.jsx";
import HomePage from "./modules/home/home.view.jsx";
import { QHDT } from "./modules/qhdt/qhdt.view.jsx";
import DialogWarningExpired from "./components/dialog-warning-expired/dialog-warning-expired.view";
import LockScreen from "./components/lock-screen/lock-screen.view.jsx";
// import { useMediaQuery } from "react-responsive";
import { useState, useEffect } from "react";
import ReactGA from "react-ga4";


// sarang
import HerdsmanList from "./pages/list-herdsman/list-herdsman.jsx";
import HerdsmanWMList from "./pages/list-herdsman/list-herdsman-wm.jsx";
import HerdsmanListPointThreeMonth from "./pages/list-herdsman/list-herdsman-point-three-month.jsx";
import { UrlCollectionSarang } from "./common/url-collection-sarang.js";
//import SheepList from "./pages/list-sheep/list-sheep.jsx";
import CustomerList from "./pages/list-customer/list-customer.jsx";
import CustomerNeedHelpList from "./pages/list-customer/list-customer-need-help.jsx";
import BreadcrumbConfig from "./breadcrumb.js";
import SwatReportList from "./pages/report-activity-swat/list-swat-report";
import DashboardHome from "./pages/dashboard-home/dashboard-home.jsx";
import ExamDashboard from "./pages/examination-dashboard/exam-dashboard.jsx";
import BlankPage from "./pages/dashboard-home/blankpage.jsx";
//import CookingRegisterList from "./pages/list-cuisine-order/list-cooking-register";
import CuisineOrderList from "./pages/list-cuisine-order/list-cuisine-order";
//import DashboardCuisineOrder from "./pages/list-cuisine-order/dashboard-cuisine-order.jsx";
//import TransportList from "./pages/list-transport-order/list-transport-order";
//import SendCuisineToShipper from "./pages/list-transport-order/send-cuisine-to-shipper.jsx";
import DepartmentList from "./pages/list-department/list-department";
/*import DepartmentListConfirmRank from "./pages/list-department/list-department-confirm-rank.jsx";*/
//import GroupList from "./pages/list-group/list-group";
//import PositionList from "./pages/list-position/list-position.jsx";
import MediaList from "./pages/list-media/list-media.jsx";
import ProfilePage from "./pages/profile/profile.jsx";
import CalendarContact from "./pages/calendar-contact/index.js";
import CalendarNeedHelp from "./pages/calendar-contact/list-task/list-task-need-help.jsx";
import ZionAreaList from "./pages/list-zion-area/list-zion-area.jsx";
import RptCustomerStepDepartment from "./pages/sumary-report/report-customer-step-department.jsx";
import FeedbackList from "./pages/feedback/list-feedback.jsx";
import ExamHerdsmanReport from "./pages/sumary-report/examination-herdsman-report.jsx";
import ExamDepartmentReport from "./pages/sumary-report/examination-department-report.jsx";
import ExamDeliveryReport from "./pages/sumary-report/examination-delivery-report.jsx";
import ExamSumaryDepartmentReport from "./pages/sumary-report/examination-sumary-report.jsx";
/*import HerdsmanTalangSumaryReport from "./pages/sumary-report/herdsman-talang-sumary-report.jsx";*/
import MyReportAcademy from "./pages/report-academy/my-report-academy.jsx";
import ExamAcademyDashboard from "./pages/report-academy/exam-academy-dashboard.jsx";
import ReportSumaryAcademy from "./pages/report-academy/report-sumaries-academy.jsx";
import SwatTrundoInput from "./pages/swat-trundo/swat-trundo-input.jsx";
import SwatTrundoSumaryHerdsmanReport from "./pages/swat-trundo/report/sumary-herdsman-report.jsx";
import SwatTrundoSumaryHerdsmanWMTeamReport from "./pages/swat-trundo/report/sumary-herdsman-wmteam-report.jsx";
import SwatTrundoSumaryDepartmentReport from "./pages/swat-trundo/report/sumary-department-report.jsx";
import SwatTrundoSumaryZionReport from "./pages/swat-trundo/report/sumary-zion-report.jsx";
import SwatTrundoSumaryWMTeamReport from "./pages/swat-trundo/report/sumary-wmteam-report.jsx";
import SwatTrundoSumaryByDateReport from "./pages/swat-trundo/report/sumary-by-date-report.jsx";
import MemberCountInput from "./pages/member-count/member-count-input.jsx";
import HerdsmanAcademyConfirm from "./pages/herdsman-academy/herdsman-academy-confirm.jsx"; 
function App() {
    ReactGA.initialize("G-82Y8T19K4Y");
  //media query
  // const isDesktopOrLaptop = useMediaQuery({
  //   query: "(min-width: 1224px)",
  // });
  // const isBigScreen = useMediaQuery({ query: "(min-width: 1824px)" });
  // const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  // const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
  // const isRetina = useMediaQuery({ query: "(min-resolution: 2dppx)" });

  // const reactMediaQuery = {
  //   isDesktopOrLaptop:isDesktopOrLaptop,
  //   isBigScreen:isBigScreen,
  //   isTabletOrMobile:isTabletOrMobile,
  //   isPortrait:isPortrait,
  //   isRetina:isRetina
    // }

  return (
    <div>
      <DialogWarningExpired />
      <Router history={history}>
        <AppLoading />
        <LockScreen />
        <Switch>
                  
                  <RouteComponent
                      exact
                      layout={() => (
                          <LayoutSarangView
                              title="Trang chủ"
                              breadcrumbList={BreadcrumbConfig.DashboardHome.breadcrumbList}
                              breadcrumbActive={BreadcrumbConfig.DashboardHome.breadcrumbActive}
                          >
                              <DashboardHome />
                          </LayoutSarangView>
                      )}
                      component={() => <div></div>}
                      path={UrlCollectionSarang.DashBoardHome}
                  />
                  <RouteComponent
                      exact
                      layout={() => (
                          <LayoutSarangView
                              title="Vinh danh"
                              breadcrumbList={BreadcrumbConfig.DashboardHome.breadcrumbList}
                              breadcrumbActive={BreadcrumbConfig.DashboardHome.breadcrumbActive}
                          >
                              <ExamDashboard />
                          </LayoutSarangView>
                      )}
                      component={ExamDashboard}
                      path={UrlCollectionSarang.ExamDashboard}
                  />
                  <RouteComponent
                      layout={() => (
                          <LayoutSarangView
                              title="Danh sách T.Đồ"
                              breadcrumbList={BreadcrumbConfig.HerdsmanList.breadcrumbList}
                              breadcrumbActive={BreadcrumbConfig.HerdsmanList.breadcrumbActive}
                          >
                              <HerdsmanList />
                          </LayoutSarangView>
                      )}
                      component={HerdsmanList}
                      path={UrlCollectionSarang.HerdsmanList}
                  />
                  <RouteComponent
                      layout={() => (
                          <LayoutSarangView
                              title="Danh sách T.Đồ WM"
                              breadcrumbList={BreadcrumbConfig.HerdsmanWMList.breadcrumbList}
                              breadcrumbActive={BreadcrumbConfig.HerdsmanWMList.breadcrumbActive}
                          >
                              <HerdsmanWMList />
                          </LayoutSarangView>
                      )}
                      component={HerdsmanWMList}
                      path={UrlCollectionSarang.HerdsmanWMList}
                  />
                  <RouteComponent
                      layout={() => (
                          <LayoutSarangView
                              title="Điểm tích lũy swat"
                              breadcrumbList={BreadcrumbConfig.HerdsmanListPointThreeMonth.breadcrumbList}
                              breadcrumbActive={BreadcrumbConfig.HerdsmanListPointThreeMonth.breadcrumbActive}
                          >
                              <HerdsmanListPointThreeMonth />
                          </LayoutSarangView>
                      )}
                      component={HerdsmanListPointThreeMonth}
                      path={UrlCollectionSarang.HerdsmanListPointThreeMonth}
                  />
                  <RouteComponent
                      layout={() => (
                          <LayoutSarangView
                              title="Danh sách ta-lâng"
                              breadcrumbList={BreadcrumbConfig.CustomerList.breadcrumbList}
                              breadcrumbActive={BreadcrumbConfig.CustomerList.breadcrumbActive}
                          >
                              {/*<SheepList />*/}
                              <CustomerList />
                          </LayoutSarangView>
                      )}
                      component={CustomerList}
                      path={UrlCollectionSarang.CustomerList}
                  />
                  <RouteComponent
                      layout={() => (
                          <LayoutSarangView
                              title="Ta-lâng cần bàn giao"
                              breadcrumbList={BreadcrumbConfig.CustomerNeedHelpList.breadcrumbList}
                              breadcrumbActive={BreadcrumbConfig.CustomerNeedHelpList.breadcrumbActive}
                          >
                              {/*<SheepList />*/}
                              <CustomerNeedHelpList />
                          </LayoutSarangView>
                      )}
                      component={CustomerNeedHelpList}
                      path={UrlCollectionSarang.CustomerNeedHelpList}
                  />
                  {/*
                  <RouteComponent
                      layout={() => (
                          <LayoutSarangView
                              title="Báo cáo hoạt động Swat"
                              breadcrumbList={BreadcrumbConfig.ReportActivitySwat.breadcrumbList}
                              breadcrumbActive={BreadcrumbConfig.ReportActivitySwat.breadcrumbActive}
                          >
                              <ReportActivitySwatList />
                          </LayoutSarangView>
                      )}
                      component={ReportActivitySwatList}
                      path={UrlCollectionSarang.ReportActivitySwat}
                  />
                  */}
                  <RouteComponent
                      layout={() => (
                          <LayoutSarangView
                              title="Danh sách đăng ký suất ăn"
                              breadcrumbList={BreadcrumbConfig.CuisineOrder.breadcrumbList}
                              breadcrumbActive={BreadcrumbConfig.CuisineOrder.breadcrumbActive}
                          >
                              <CuisineOrderList />
                          </LayoutSarangView>
                      )}
                      component={CuisineOrderList}
                      path={UrlCollectionSarang.CuisineOrder}
                  />
                  
                  {/*<RouteComponent*/}
                  {/*    layout={() => (*/}
                  {/*        <LayoutSarangView*/}
                  {/*            title="Theo dõi Đơn hàng"*/}
                  {/*            breadcrumbList={BreadcrumbConfig.ReportActivityShipping.breadcrumbList}*/}
                  {/*            breadcrumbActive={BreadcrumbConfig.ReportActivityShipping.breadcrumbActive}*/}
                  {/*        >*/}
                  {/*            <TransportList />*/}
                  {/*        </LayoutSarangView>*/}
                  {/*    )}*/}
                  {/*    component={TransportList}*/}
                  {/*    path={UrlCollectionSarang.ReportActivityShipping}*/}
                  {/*/>*/}
                  {/*<RouteComponent*/}
                  {/*    layout={() => (*/}
                  {/*        <LayoutSarangView*/}
                  {/*            title="Giao shipper"*/}
                  {/*            breadcrumbList={BreadcrumbConfig.SendCuisineToShipper.breadcrumbList}*/}
                  {/*            breadcrumbActive={BreadcrumbConfig.ReportActivityShipping.breadcrumbActive}*/}
                  {/*        >*/}
                  {/*            <SendCuisineToShipper />*/}
                  {/*        </LayoutSarangView>*/}
                  {/*    )}*/}
                  {/*    component={SendCuisineToShipper}*/}
                  {/*    path={UrlCollectionSarang.SendCuisineToShipper}*/}
                  {/*/>*/}
                  <RouteComponent
                      layout={() => (
                          <LayoutSarangView
                              title="Biệt đội"
                              breadcrumbList={BreadcrumbConfig.Department.breadcrumbList}
                              breadcrumbActive={BreadcrumbConfig.Department.breadcrumbActive}
                          >
                              <DepartmentList />
                          </LayoutSarangView>
                      )}
                      component={DepartmentList}
                      path={UrlCollectionSarang.Department}
                  />
                  <RouteComponent
                      layout={() => (
                          <LayoutSarangView
                              title="Biệt đội"
                              breadcrumbList={BreadcrumbConfig.ZionArea.breadcrumbList}
                              breadcrumbActive={BreadcrumbConfig.ZionArea.breadcrumbActive}
                          >
                              <ZionAreaList />
                          </LayoutSarangView>
                      )}
                      component={ZionAreaList}
                      path={UrlCollectionSarang.ZionArea}
                  />
                  {/*<RouteComponent*/}
                  {/*    layout={() => (*/}
                  {/*        <LayoutSarangView*/}
                  {/*            title="Duyệt thăng hạng"*/}
                  {/*            breadcrumbList={BreadcrumbConfig.DepartmentListConfirmRank.breadcrumbList}*/}
                  {/*            breadcrumbActive={BreadcrumbConfig.DepartmentListConfirmRank.breadcrumbActive}*/}
                  {/*        >*/}
                  {/*            <DepartmentListConfirmRank />*/}
                  {/*        </LayoutSarangView>*/}
                  {/*    )}*/}
                  {/*    component={DepartmentListConfirmRank}*/}
                  {/*    path={UrlCollectionSarang.DepartmentListConfirmRank}*/}
                  {/*/>*/}
                  <RouteComponent
                      layout={() => (
                          <LayoutSarangView
                              title="Sự cố"
                              breadcrumbList={BreadcrumbConfig.Feedback.breadcrumbList}
                              breadcrumbActive={BreadcrumbConfig.Feedback.breadcrumbActive}
                          >
                              <FeedbackList />
                          </LayoutSarangView>
                      )}
                      component={FeedbackList}
                      path={UrlCollectionSarang.Feedback}
                  />
                  <RouteComponent
                      layout={() => (
                          <LayoutSarangView
                              title="Báo cáo hoạt động trundo"
                              breadcrumbList={BreadcrumbConfig.SwatTrundoInput.breadcrumbList}
                              breadcrumbActive={BreadcrumbConfig.SwatTrundoInput.breadcrumbActive}
                          >
                              <SwatTrundoInput />
                          </LayoutSarangView>
                      )}
                      component={SwatTrundoInput}
                      path={UrlCollectionSarang.SwatTrundoInput}
                  />
                  <RouteComponent
                      layout={() => (
                          <LayoutSarangView
                              title="Báo cáo hoạt động trundo theo T/Đồ"
                              breadcrumbList={BreadcrumbConfig.SwatTrundoSumaryHerdsmanReport.breadcrumbList}
                              breadcrumbActive={BreadcrumbConfig.SwatTrundoSumaryHerdsmanReport.breadcrumbActive}
                          >
                              <SwatTrundoSumaryHerdsmanReport />
                          </LayoutSarangView>
                      )}
                      component={SwatTrundoSumaryHerdsmanReport}
                      path={UrlCollectionSarang.SwatTrundoSumaryHerdsmanReport}
                  />
                  <RouteComponent
                      layout={() => (
                          <LayoutSarangView
                              title="Tổng hợp hoạt động trundo theo T/Đồ WM"
                              breadcrumbList={BreadcrumbConfig.SwatTrundoSumaryHerdsmanWMTeamReport.breadcrumbList}
                              breadcrumbActive={BreadcrumbConfig.SwatTrundoSumaryHerdsmanWMTeamReport.breadcrumbActive}
                          >
                              <SwatTrundoSumaryHerdsmanWMTeamReport />
                          </LayoutSarangView>
                      )}
                      component={SwatTrundoSumaryHerdsmanWMTeamReport}
                      path={UrlCollectionSarang.SwatTrundoSumaryHerdsmanWMTeamReport}
                  />
                  <RouteComponent
                      layout={() => (
                          <LayoutSarangView
                              title="Tổng hợp hoạt động trundo theo địa vực"
                              breadcrumbList={BreadcrumbConfig.SwatTrundoSumaryDepartmentReport.breadcrumbList}
                              breadcrumbActive={BreadcrumbConfig.SwatTrundoSumaryDepartmentReport.breadcrumbActive}
                          >
                              <SwatTrundoSumaryDepartmentReport />
                          </LayoutSarangView>
                      )}
                      component={SwatTrundoSumaryDepartmentReport}
                      path={UrlCollectionSarang.SwatTrundoSumaryDepartmentReport}
                  />
                  <RouteComponent
                      layout={() => (
                          <LayoutSarangView
                              title="Tổng hợp hoạt động trundo theo khu vực"
                              breadcrumbList={BreadcrumbConfig.SwatTrundoSumaryZionReport.breadcrumbList}
                              breadcrumbActive={BreadcrumbConfig.SwatTrundoSumaryZionReport.breadcrumbActive}
                          >
                              <SwatTrundoSumaryZionReport />
                          </LayoutSarangView>
                      )}
                      component={SwatTrundoSumaryZionReport}
                      path={UrlCollectionSarang.SwatTrundoSumaryZionReport}
                  />
                  <RouteComponent
                      layout={() => (
                          <LayoutSarangView
                              title="Báo cáo hoạt động trundo theo WM"
                              breadcrumbList={BreadcrumbConfig.SwatTrundoSumaryWMTeamReport.breadcrumbList}
                              breadcrumbActive={BreadcrumbConfig.SwatTrundoSumaryWMTeamReport.breadcrumbActive}
                          >
                              <SwatTrundoSumaryWMTeamReport />
                          </LayoutSarangView>
                      )}
                      component={SwatTrundoSumaryWMTeamReport}
                      path={UrlCollectionSarang.SwatTrundoSumaryWMTeamReport}
                  />
                  <RouteComponent
                      layout={() => (
                          <LayoutSarangView
                              title="Chi tiết hoạt động trundo theo T/Đồ"
                              breadcrumbList={BreadcrumbConfig.SwatTrundoSumaryByDateReport.breadcrumbList}
                              breadcrumbActive={BreadcrumbConfig.SwatTrundoSumaryByDateReport.breadcrumbActive}
                          >
                              <SwatTrundoSumaryByDateReport />
                          </LayoutSarangView>
                      )}
                      component={SwatTrundoSumaryByDateReport}
                      path={UrlCollectionSarang.SwatTrundoSumaryByDateReport}
                  />
                  <RouteComponent
                      layout={() => (
                          <LayoutSarangView
                              title="Đăng ký số lượng phát biểu"
                              breadcrumbList={BreadcrumbConfig.MemberCountInput.breadcrumbList}
                              breadcrumbActive={BreadcrumbConfig.MemberCountInput.breadcrumbActive}
                          >
                              <MemberCountInput />
                          </LayoutSarangView>
                      )}
                      component={MemberCountInput}
                      path={UrlCollectionSarang.MemberCountInput}
                  />
                  <RouteComponent
                      layout={() => (
                          <LayoutSarangView
                              title="Xác nhận phát biểu GĐ2"
                              breadcrumbList={BreadcrumbConfig.HerdsmanAcademyConfirm.breadcrumbList}
                              breadcrumbActive={BreadcrumbConfig.HerdsmanAcademyConfirm.breadcrumbActive}
                          >
                              <HerdsmanAcademyConfirm />
                          </LayoutSarangView>
                      )}
                      component={HerdsmanAcademyConfirm}
                      path={UrlCollectionSarang.HerdsmanAcademyConfirm}
                  />
                  <RouteComponent
                      layout={() => (
                          <LayoutSarangView
                              title="Hướng dẫn"
                              breadcrumbList={BreadcrumbConfig.Helping.breadcrumbList}
                              breadcrumbActive={BreadcrumbConfig.Helping.breadcrumbActive}
                          >
                              <MediaList />
                          </LayoutSarangView>
                      )}
                      component={MediaList}
                      path={UrlCollectionSarang.Helping}
                  />
                  <RouteComponent
                      layout={() => (
                          <LayoutSarangView
                              title="Hội thánh/chi hội"
                              breadcrumbList={BreadcrumbConfig.Organization.breadcrumbList}
                              breadcrumbActive={BreadcrumbConfig.Organization.breadcrumbActive}
                          >
                              <CalendarContact />
                          </LayoutSarangView>
                      )}
                      component={CalendarContact}
                      path={UrlCollectionSarang.Organization}
                  />
                  {/*<RouteComponent*/}
                  {/*    layout={() => (*/}
                  {/*        <LayoutSarangView*/}
                  {/*            title="Biểu đồ tổng hợp Ẩm thực"*/}
                  {/*            breadcrumbList={BreadcrumbConfig.DashboardCooking.breadcrumbList}*/}
                  {/*            breadcrumbActive={BreadcrumbConfig.DashboardCooking.breadcrumbActive}*/}
                  {/*        >*/}
                  {/*            <DashboardCuisineOrder />*/}
                  {/*        </LayoutSarangView>*/}
                  {/*    )}*/}
                  {/*    component={DashboardCuisineOrder}*/}
                  {/*    path={UrlCollectionSarang.DashboardCooking}*/}
                  {/*/>*/}
                  <RouteComponent
                      layout={() => (
                          <LayoutSarangView
                              title="Biểu đồ tổng hợp Đơn hàng"
                              breadcrumbList={BreadcrumbConfig.DashboardShipping.breadcrumbList}
                              breadcrumbActive={BreadcrumbConfig.DashboardShipping.breadcrumbActive}
                          >
                              <BlankPage />
                          </LayoutSarangView>
                      )}
                      component={BlankPage}
                      path={UrlCollectionSarang.DashboardShipping}
                  />

          <RouteComponent
            exact
            layout={LayoutUserView}
            component={Login}
            path={UrlCollection.Login}
            isSetActive={false}
          />
          <RouteComponent
            exact
            layout={LayoutUserView}
            component={ForgotPassword}
            path={UrlCollection.ForgotPassword}
            isSetActive={false}
          />
          <RouteComponent
            exact
            layout={LayoutUserView}
            component={ResetPassword}
            path={UrlCollection.ResetPassword}
            isSetActive={false}
          />
          <RouteComponent
            exact
            layout={() => (
              <LayoutView title="Dashboard">
                <Dashboard />
              </LayoutView>
            )}
            component={Dashboard}
            path={UrlCollection.Dashboard}
          />

          <RouteComponent
            exact
            layout={HomePage}
            component={HomePage}
            path={UrlCollection.Home}
          />
          <RouteComponent
            exact
            layout={QHDT}
            component={QHDT}
            path={UrlCollection.QHDT}
                  />

                  
          <RouteComponent
            exact
            layout={() => (
              <LayoutView title="Email Template">
                <EmailTemplate />
              </LayoutView>
            )}
            path={UrlCollection.EmailTemplate}
          />

          {/*Email Generated */}
          <RouteComponent
            exact
            layout={() => (
              <LayoutView title="Email Generated">
                <EmailGenerated />
              </LayoutView>
            )}
            path={UrlCollection.EmailGenerated}
          />

          {/* Role Management */}
          <RouteComponent
            exact
            layout={() => (
              <LayoutView title="Role Management">
                <RoleManagement />
              </LayoutView>
            )}
            path={UrlCollection.RoleManagement}
          />

          {/* Contact Management */}
          <RouteComponent
            exact
            layout={() => (
              <LayoutView title="Liên hệ">
                <ContactManagement />
              </LayoutView>
            )}
            path={UrlCollection.ContactManagement}
          />
          {/* Log */}
          <RouteComponent
            exact
            layout={() => (
              <LayoutView title="Quản lý log">
                <Log />
              </LayoutView>
            )}
            path={UrlCollection.Log}
          />
          {/* User Management */}
          <RouteComponent
            exact
            layout={() => (
              <LayoutView title="Quản lý người dùng">
                <UserManagement />
              </LayoutView>
            )}
            path={UrlCollection.UserManagement}
          />

          {/* commune Management */}
          <RouteComponent
            exact
            layout={() => (
              <LayoutView title="Quản lý xã phường">
                <CommuneManagement />
              </LayoutView>
            )}
            path={UrlCollection.CommuneManagement}
          />

          {/* District Management */}
          <RouteComponent
            exact
            layout={() => (
              <LayoutView title="Quản lý quận/huyện">
                <DistrictManagement />
              </LayoutView>
            )}
            path={UrlCollection.DistrictManagement}
          />

          {/* Province Management */}
          <RouteComponent
            exact
            layout={() => (
              <LayoutView title="Quản lý tỉnh">
                <ProviceManagement />
              </LayoutView>
            )}
            component={ProviceManagement}
            path={UrlCollection.ProvinceManagement}
          />

         
          <RouteComponent
            exact
            layout={() => (
              <LayoutView title="Nhật ký người dùng">
                <UserLogHistory />
              </LayoutView>
            )}
            path={UrlCollection.UserLogHistory}
          />

          
          {/* ApprovalAgency */}
          <RouteComponent
            exact
            layout={() => (
              <LayoutView title="Cơ quan phê duyệt">
                <ApprovalAgency />
              </LayoutView>
            )}
            path={UrlCollection.ApprovalAgency}
          />

          {/* Land Type */}
          <RouteComponent
            exact
            layout={() => (
              <LayoutView title="Quản lý chi tiết loại đất">
                <LandTypeDetailManagement />
              </LayoutView>
            )}
            path={UrlCollection.LandTypeDetail}
          />

          <RouteComponent
            exact
            layout={() => (
              <LayoutView title="Đơn vị lập quy hoạch">
                <PlanningUnit />
              </LayoutView>
            )}
            path={UrlCollection.PlanningUnit}
          />

          {/* my account */}
          <RouteComponent
            exact
            layout={() => (
              <LayoutView title="">
                <MyAccount />
              </LayoutView>
            )}
            path={UrlCollection.MyAccount}
          />
          {/* Table Layer Structure */}
          <RouteComponent
            exact
            layout={() => (
              <LayoutView title="">
                <TableStructure />
              </LayoutView>
            )}
            path={UrlCollection.TableStructure}
          />
          
          <RouteComponent
          exact
            layout={() => (
              <LayoutSarangView
                title="Trang chủ"
                breadcrumbList={BreadcrumbConfig.DashboardHome.breadcrumbList}
                breadcrumbActive={BreadcrumbConfig.DashboardHome.breadcrumbActive}
              >
                <DashboardHome/>
              </LayoutSarangView>
            )}
            component={() => <div></div>}
            path={UrlCollectionSarang.DashBoardHome}
          />
          <RouteComponent
            layout={() => (
              <LayoutSarangView
                title="Danh sách swat"
                breadcrumbList={BreadcrumbConfig.HerdsmanList.breadcrumbList}
                breadcrumbActive={BreadcrumbConfig.HerdsmanList.breadcrumbActive}
              >
                <HerdsmanList/>
              </LayoutSarangView>
            )}
            component={HerdsmanList}
            path={UrlCollectionSarang.HerdsmanList}
          />
          <RouteComponent
            layout={() => (
              <LayoutSarangView
                title="Danh sách ta-lâng"
                breadcrumbList={BreadcrumbConfig.CustomerList.breadcrumbList}
                breadcrumbActive={BreadcrumbConfig.CustomerList.breadcrumbActive}
              >
                {/*<SheepList />*/}
                <CustomerList />
              </LayoutSarangView>
            )}
            component={CustomerList}
            path={UrlCollectionSarang.CustomerList}
          />
          <RouteComponent
            layout={() => (
              <LayoutSarangView
                title="Lịch hẹn chia sẻ"
                breadcrumbList={BreadcrumbConfig.CalendarContact.breadcrumbList}
                breadcrumbActive={BreadcrumbConfig.CalendarContact.breadcrumbActive}
              >
                <CalendarContact />
              </LayoutSarangView>
            )}
            component={CalendarContact}
            path={UrlCollectionSarang.CalendarContact}
          />
          <RouteComponent
            layout={() => (
              <LayoutSarangView
                title="Ca chia sẻ cần trợ giúp"
                breadcrumbList={BreadcrumbConfig.CalendarNeedHelp.breadcrumbList}
                breadcrumbActive={BreadcrumbConfig.CalendarNeedHelp.breadcrumbActive}
              >
                <CalendarNeedHelp />
              </LayoutSarangView>
            )}
            component={CalendarNeedHelp}
            path={UrlCollectionSarang.CalendarNeedHelp}
          />
          <RouteComponent
            layout={() => (
              <LayoutSarangView
                title="Tổng hợp ta lâng theo bước"
                breadcrumbList={BreadcrumbConfig.RptCustomerStepDepartment.breadcrumbList}
                breadcrumbActive={BreadcrumbConfig.RptCustomerStepDepartment.breadcrumbActive}
              >
                              <RptCustomerStepDepartment />
              </LayoutSarangView>
            )}
            component={RptCustomerStepDepartment}
            path={UrlCollectionSarang.RptCustomerStepDepartment}
                  />
          <RouteComponent
            layout={() => (
              <LayoutSarangView
                title="Báo cáo xếp hạng trudo SWAT"
                breadcrumbList={BreadcrumbConfig.ExamHerdsmanReport.breadcrumbList}
                breadcrumbActive={BreadcrumbConfig.ExamHerdsmanReport.breadcrumbActive}
              >
                              <ExamHerdsmanReport />
              </LayoutSarangView>
            )}
            component={ExamHerdsmanReport}
            path={UrlCollectionSarang.ExamHerdsmanReport}
          />
                  <RouteComponent
                      layout={() => (
                          <LayoutSarangView
                              title="Báo cáo xếp hạng trudo SWAT"
                              breadcrumbList={BreadcrumbConfig.ExamHerdsmanReport.breadcrumbList}
                              breadcrumbActive={BreadcrumbConfig.ExamHerdsmanReport.breadcrumbActive}
                          >
                              <ExamDepartmentReport />
                          </LayoutSarangView>
                      )}
                      component={ExamDepartmentReport}
                      path={UrlCollectionSarang.ExamDepartmentReport}
                  />
                  <RouteComponent
                      layout={() => (
                          <LayoutSarangView
                              title="Báo cáo xếp hạng trudo SWAT"
                              breadcrumbList={BreadcrumbConfig.ExamDeliveryReport.breadcrumbList}
                              breadcrumbActive={BreadcrumbConfig.ExamDeliveryReport.breadcrumbActive}
                          >
                              <ExamDeliveryReport />
                          </LayoutSarangView>
                      )}
                      component={ExamDeliveryReport}
                      path={UrlCollectionSarang.ExamDeliveryReport}
                  />
                  <RouteComponent
                      layout={() => (
                          <LayoutSarangView
                              title="Tổng hợp hoạt động trundo theo biệt đội"
                              breadcrumbList={BreadcrumbConfig.ExamDeliveryReport.breadcrumbList}
                              breadcrumbActive={BreadcrumbConfig.ExamDeliveryReport.breadcrumbActive}
                          >
                              <ExamSumaryDepartmentReport />
                          </LayoutSarangView>
                      )}
                      component={ExamSumaryDepartmentReport}
                      path={UrlCollectionSarang.ExamSumaryDepartmentReport}
                  />
                  {/*<RouteComponent*/}
                  {/*    layout={() => (*/}
                  {/*        <LayoutSarangView*/}
                  {/*            title="Báo cáo tổng hợp thể ta-lâng"*/}
                  {/*            breadcrumbList={BreadcrumbConfig.ExamDeliveryReport.breadcrumbList}*/}
                  {/*            breadcrumbActive={BreadcrumbConfig.ExamDeliveryReport.breadcrumbActive}*/}
                  {/*        >*/}
                  {/*            <HerdsmanTalangSumaryReport />*/}
                  {/*        </LayoutSarangView>*/}
                  {/*    )}*/}
                  {/*    component={HerdsmanTalangSumaryReport}*/}
                  {/*    path={UrlCollectionSarang.HerdsmanTalangSumaryReport}*/}
                  {/*/>*/}
                  <RouteComponent
                      layout={() => (
                          <LayoutSarangView
                              title="Phát biểu lễ hội lời"
                              breadcrumbList={BreadcrumbConfig.MyReportAcademy.breadcrumbList}
                              breadcrumbActive={BreadcrumbConfig.MyReportAcademy.breadcrumbActive}
                          >
                              <MyReportAcademy />
                          </LayoutSarangView>
                      )}
                      component={MyReportAcademy}
                      path={UrlCollectionSarang.MyReportAcademy}
                  />
                  <RouteComponent
                      layout={() => (
                          <LayoutSarangView
                              title="Vinh danh lễ hội lời"
                              breadcrumbList={BreadcrumbConfig.ExamAcademyDashboard.breadcrumbList}
                              breadcrumbActive={BreadcrumbConfig.ExamAcademyDashboard.breadcrumbActive}
                          >
                              <ExamAcademyDashboard />
                          </LayoutSarangView>
                      )}
                      component={ExamAcademyDashboard}
                      path={UrlCollectionSarang.ExamAcademyDashboard}
                  />
                  <RouteComponent
                      layout={() => (
                          <LayoutSarangView
                              title="Thống kê lễ hội lời"
                              breadcrumbList={BreadcrumbConfig.ExamAcademyDashboard.breadcrumbList}
                              breadcrumbActive={BreadcrumbConfig.ExamAcademyDashboard.breadcrumbActive}
                          >
                              <ReportSumaryAcademy />
                          </LayoutSarangView>
                      )}
                      component={ReportSumaryAcademy}
                      path={UrlCollectionSarang.ReportSumaryAcademy}
                  />
                  
          <RouteComponent
            layout={() => (
              <LayoutSarangView
                title="Báo cáo hoạt động Swat"
                breadcrumbList={BreadcrumbConfig.SwatReportList.breadcrumbList}
                breadcrumbActive={BreadcrumbConfig.SwatReportList.breadcrumbActive}
              >
                <SwatReportList />
              </LayoutSarangView>
            )}
            component={SwatReportList}
            path={UrlCollectionSarang.SwatReportList}
          />
          {/*<RouteComponent*/}
          {/*  layout={() => (*/}
          {/*    <LayoutSarangView*/}
          {/*      title="Danh sách đăng ký suất ăn"*/}
          {/*      breadcrumbList={BreadcrumbConfig.CuisineOrder.breadcrumbList}*/}
          {/*      breadcrumbActive={BreadcrumbConfig.CuisineOrder.breadcrumbActive}*/}
          {/*    >*/}
          {/*      <CuisineOrderList />*/}
          {/*    </LayoutSarangView>*/}
          {/*  )}*/}
          {/*   component={CuisineOrderList}*/}
          {/*  path={UrlCollectionSarang.CuisineOrder}*/}
          {/*/>*/}
          
          
          <RouteComponent
            layout={() => (
              <LayoutSarangView
                title="Biểu đồ tổng hợp Đơn hàng"
                breadcrumbList={BreadcrumbConfig.DashboardShipping.breadcrumbList}
                breadcrumbActive={BreadcrumbConfig.DashboardShipping.breadcrumbActive}
              >
                <SwatReportList/>
              </LayoutSarangView>
            )}
            component={SwatReportList}
            path={UrlCollectionSarang.DashboardShipping}
          />
          <RouteComponent
            layout={() => (
              <LayoutSarangView
                title="Tài khoản"
                breadcrumbList={BreadcrumbConfig.Profile.breadcrumbList}
                breadcrumbActive={BreadcrumbConfig.Profile.breadcrumbActive}
              >
                <ProfilePage/>
              </LayoutSarangView>
            )}
            component={ProfilePage}
            path={UrlCollectionSarang.Profile}
          />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
